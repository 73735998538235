import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate, useParams } from "react-router-dom";
import "../private-competitions/PrivateCompetition.css";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import ProfileAccount from "../account/ProfileAccount";
import Parser from "html-react-parser";
import ApiContext from "../../providers/Api/ApiContext";
import { useForm } from "react-hook-form";

export default function CreatePrivateCompetition() {
  const { api, soccerApi } = useContext(ApiContext);
  const [loader, showLoader, hideLoader] = useLoading();
  const [sports, setSports] = useState([
    {
      sport_id: 1,
      sport_name: "Soccer",
    }])
  const [leagues, setLeagues] = useState([]);
  const [alert, alert_notify] = useAlert();
  const [successStatus, setSuccesStatus] = useState(false);
  const { sportId, leagueId } = useParams();
  const navigate = useNavigate();
  const [cpLabels, setCpLabels] = useState([]);
  const [cpStaticContent, setCpStaticContent] = useState();
  const [contests, setContests] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      competition_title: "",
      entry_fee: '',
      entrants: '',
      sportsFirst: 0,
      sportsSecond: 0,
      sportsThird: 0,
      sportsClub: 0,
      rake: 10,
      currency: "EUR",
      sport_id: 0,
      league_id: 0,
      contest_id: 0,
    },
  });
  const formValues = watch();
  const getCreatePrivateCompetitionStaticContent = () => {
    var content_url = "/api/static_content/get_all_static_content";
    const body = { page_meta_key: "create_private_competiton" };
    showLoader();
    api
      .post(content_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data;
          var pageContent = data.content.page_content;
          if (pageContent != null && pageContent != undefined) {
            setCpStaticContent(pageContent);
          } else {
            setCpStaticContent(
              "Please select one option only from each of the following:"
            );
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };


  const submitCreatePrivateCompetition = (data) => {
    var create_private_competition_url = "/contest/request_contest";
      var body = {
        competition_title: formValues.competition_title,
        contest_type: "private",
        league_id: formValues.league_id,
        contest_id: formValues.contest_id,
        entry_fee: formValues.entry_fee,
        num_of_entrants: formValues.entrants,
        payout_1st: "0",
        payout_2nd: "0",
        payout_3rd: "0",
        payout_club: "0",
        rake:"10",
        sport_id: Number(formValues.sport_id),
        currency:formValues.currency
      };
      showLoader();
      soccerApi
        .post(create_private_competition_url, body)
        .then((response) => {
          if (response.status === 200) {
            setSuccesStatus(true);
            alert_notify(
              "success",
              "Private competition request has been made successfully."
            );
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
  };

  const getCreatePrivateCompetitionsLabels = () => {
    var cp_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "create private competition",
    };
    showLoader();
    api
      .post(cp_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setCpLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
      });
  };

  useEffect(()=>{
    getCreatePrivateCompetitionStaticContent();
    getCreatePrivateCompetitionsLabels()
  },[])

 
  const fetchLeagues = async (sportId) => {
    try {
      if (sportId == 0) {
        setLeagues([]);
        return;
      }

      showLoader();
      let result = await soccerApi.get(`/get_leagues`);
      setLeagues(result.data.data.content ?? []);
    } catch (error) {
      alert_notify(error.message);
    } finally {
      hideLoader();
    }
  };
  const getContestByLeagueId = (leagueId) => {
    var content_url = `/contest/fetch_contest/${leagueId}`;
    showLoader();
    soccerApi
      .get(content_url)
      .then((response) => {
        if (response.status === 200) {
          setContests(response.data.data.contest);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !cpLabels.create_private_competition_page_label
            ? "CREATE PRIVATE COMPETITION"
            : cpLabels.create_private_competition_page_label.label_name
        }
      />
      <div className="create_private_competition_container primary-background">
        <div className="page_content_center">
          {successStatus ? (
            <SuccesNotification
              title=""
              content="Top Predictor private competition was created successfully. Check your email for the shareable link."
            />
          ) : (
            <div>
              <div className="row m-0">
                <div className="col-12">
                  <span className="private_competition_select_options text-white">
                    {cpStaticContent != null && cpStaticContent != undefined
                      ? Parser(cpStaticContent)
                      : "Please select one option only from each of the following:"}
                  </span>
                </div>
              </div>
              <form
               id="form_club_fundraiser"
               onSubmit={handleSubmit(submitCreatePrivateCompetition)}
            >
              <div className="club_fundraiser_selections">
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-5">
                    <div className="col-12 mt-4">
                      <input
                        type="text"
                        id="competition_title"
                        className="competition_title_input"
                        maxLength="50"
                        placeholder={
                          !cpLabels.create_club_fundraiser_competition_title
                            ? "Competition Title"
                            : cpLabels.create_club_fundraiser_competition_title
                                .label_name
                        }
                        {...register("competition_title", {
                          required: "Competition Title is required",
                        })}
                      />
                      {errors.competition_title && (
                        <span className="text-error-club">
                          {errors.competition_title.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        {...register("sport_id", {
                          validate: (value) =>
                            value != "0" || "Please select a sport",
                          onChange: (e) => {
                            fetchLeagues(e.target.value);
                          },
                        })}
                      >
                        <option value="0">
                          {!cpLabels.create_private_competition_sport_label
                            ? "Sport"
                            : cpLabels.create_private_competition_sport_label.label_name}
                        </option>
                        {sports.map((sport, index) => {
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                            >
                              {sport.sport_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.sport_id && (
                        <span className="text-error-club">
                          {errors.sport_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        {...register("league_id", {
                          validate: (value) =>
                            value != "0" || "League is required",
                          onChange: (e) => {
                            getContestByLeagueId(e.target.value);
                          },
                        })}
                      >
                        <option value="0">
                          {!cpLabels.create_private_competition_league_label
                            ? "League"
                            : cpLabels.create_private_competition_league_label.label_name}
                        </option>
                        {leagues.map((league, index) => {
                          return (
                            <option
                              data-id={league.league_id}
                              data-value={league.league_id}
                              value={league.league_id}
                              key={index}
                            >
                              {league.league_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.league_id && (
                        <span className="text-error-club">
                          {errors.league_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        {...register("contest_id", {
                          validate: (value) =>
                            value != "0" || "Contest is required",
                        })}
                      >
                        <option value="0">Select Contest</option>
                        {contests.map((contest, index) => {
                          return (
                            <option
                              data-id={contest.contest_id}
                              value={contest.contest_id}
                              data-value={contest.contest_id}
                              key={index}
                            >
                              ({contest.contest_uid}) {contest.contest_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.contest_id && (
                        <span className="text-error-club">
                          {errors.contest_id.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        id="create_club_fundraiser_entry_fee"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cpLabels.create_private_competition_entryfee_label
                            ? "Entry Fee (" + formValues.currency + ")"
                            : cpLabels.create_private_competition_entryfee_label
                                .label_name +
                              " (" +
                              formValues.currency +
                              ")"
                        }
                        data-type="entryfee"
                        step="1"
                        min="1"
                        {...register("entry_fee", {
                          required: "Entry fee is required",
                        })}
                      />
                      {errors.entry_fee && (
                        <span className="text-error-club">
                          {errors.entry_fee.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cpLabels.create_private_competition_entrants_label
                            ? "Entrants"
                            : cpLabels.create_private_competition_entrants_label
                                .label_name
                        }
                        data-type="entrants"
                        step="1"
                        min="2"
                        {...register("entrants", {
                          required: "Number of entries are required",
                        })}
                      />
                      {errors.entrants && (
                        <span className="text-error-club">
                          {errors.entrants.message}
                        </span>
                      )}
                    </div>
                  </div>
             
                </div>
              </div>
              <div className="row m-0 mt-4 create_club_fundraiser_btn">
                <div className="col-12">
                  <ButtonPrimary
                    classValue="btn_primary btn_create_club_fundraiser"
                    datapath="my_competition"
                    type="submit"
                    form="form_club_fundraiser"
                    textValue={
                      !cpLabels.create_private_competition_create_label
                        ? "Submit"
                        : cpLabels.create_private_competition_create_label.label_name
                    }
                  />
                </div>
              </div>
            </form>
            </div>
          )}
        </div>
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
